import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  faBars,
  faTimes,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { AnimationEvent } from '@angular/animations';
import {
  collapseAnimation,
  fadeInAnimation,
  fadeInOnEnterAnimation,
  fadeOutAnimation,
  fadeOutOnLeaveAnimation,
  rotateAnimation,
  rotateInAnimation,
  rotateOutAnimation,
} from 'angular-animations';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';

const breakpoints = [Breakpoints.XSmall, Breakpoints.Small];

interface NavigationData {
  text: string;
  url?: any[];
  children?: NavigationData[];
  icon: string;
}

@Component({
  selector: 'dp-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    collapseAnimation({ duration: 100 }),
    rotateAnimation({ degrees: 90 })
  ],
})
export class MenuComponent implements OnChanges {
  @Input() data: any;

  dataSource: MatTreeNestedDataSource<NavigationData> = new MatTreeNestedDataSource();
  treeControl: NestedTreeControl<NavigationData> = new NestedTreeControl<NavigationData>(
    (t) => t.children
  );

  nestedNodePadding = 16;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const data = changes.data;
    if (data) {
      this.dataSource.data = data.currentValue;
    }
  }

  hasChild = (_: number, node: NavigationData) =>
    !!node.children && node.children.length > 0;

  collapseAll() {
    this.treeControl.collapseAll();
    this.cd.markForCheck();
    debugger;
    // this.treeControl.
  }

}
