import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

@Component({
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {}
