import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from '@auth0/auth0-angular';

@NgModule({
  declarations: [],
  imports: [
    AuthModule.forRoot({
      domain: 'dependle.eu.auth0.com',
      clientId: 'atN5kqIz64FXWG9gpgCnUOVIW6P1G5Fl',
      // audience: 'API'
    })
  ],
  providers: [

  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
