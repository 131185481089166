<mat-tree class="sidenav-menu" [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node #nodeTree="matTreeNode" *matTreeNodeDef="let node" matTreeNodeToggle >
      <a [style.paddingLeft.px]="(nestedNodePadding*(nodeTree.level+1))" routerLinkActive="active-link" mat-button [disableRipple]="false" [routerLink]="node.url" class="node">
        <div class="icon-wrapper">
          <mat-icon *ngIf="node.icon">
            {{ node.icon }}
          </mat-icon>
          <span>{{node.text}}</span>
        </div>
      </a>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node #nestedTree="matNestedTreeNode" *matTreeNodeDef="let node;when: hasChild" >
    <div [class.expanded]="treeControl.isExpanded(node)">
      <button [style.paddingLeft.px]="(nestedNodePadding*(nestedTree.level+1))" mat-button matTreeNodeToggle [disableRipple]="false" class="node">
        <div class="icon-wrapper">
          <mat-icon *ngIf="node.icon">{{ node.icon }}</mat-icon>
          <span>{{node.text}}</span>
        </div>

        <mat-icon [@rotate]="treeControl.isExpanded(node)">
          chevron_right
        </mat-icon>
      </button>

      <div [@collapse]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </div>
  </mat-nested-tree-node>
</mat-tree>

