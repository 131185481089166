<div class="content-layout">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="snav.toggle()">
      <fa-icon style="display:inline-block;" [icon]="sidenavButtonIcon" [@rotateOut]="rotateOutAnimation"
        (@rotateOut.done)="rotateOutSidenavButtonDone($event, false)" [@rotateIn]="rotateInAnimation"
        (@rotateIn.done)="rotateInSidenavButtonDone($event, false)" [@rotateOutExpanded]="rotateOutExpandedAnimation"
        (@rotateOutExpanded.done)="rotateOutSidenavButtonDone($event, true)"
        [@rotateInExpanded]="rotateInExpandedAnimation"
        (@rotateInExpanded.done)="rotateInSidenavButtonDone($event, true)"></fa-icon>
    </button>
    <div class="logo">
      <a [routerLink]="['/']">
        <img src="assets/svg/Dependle.svg">
      </a>
    </div>

    <span class="spacer"></span>

    <button mat-icon-button>
      <fa-icon [icon]="icons.envelope"></fa-icon>
    </button>
    <ng-container *ngIf="(user | async) as userVar">
      <button mat-button [matMenuTriggerFor]="userDrop"><img class="user-button" [src]="userVar?.picture">{{
        userVar?.name }}</button>
      <mat-menu #userDrop="matMenu">
        <button mat-menu-item (click)="logout()">Log ud</button>
      </mat-menu>
    </ng-container>
  </mat-toolbar>

  <mat-sidenav-container autoSize [class.extended]="extended">
    <mat-sidenav  #snav autoSize (closedStart)="onSidenavAnimationChange(false)"
      (openedStart)="onSidenavAnimationChange(true)" [mode]="(isHandset$ | async) ? 'over' : (detached ? 'over' : 'side')"
      opened>

      <!-- <div class="detached-button">
        <mat-slide-toggle [ngModel]="!detached" (ngModelChange)="toggleMenu()"></mat-slide-toggle>
      </div> -->

      <dp-menu [data]="treeData"></dp-menu>

      <button class="expand-button" mat-button (click)="extendToggle()"><fa-icon [icon]="icons.caretRight"></fa-icon></button>

    </mat-sidenav>
    <mat-sidenav-content>

      <div class="content">
        <router-outlet></router-outlet>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>



