import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  faBars,
  faCaretRight,
  faHome,
  faTimes,
  faUser,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { AnimationEvent } from '@angular/animations';
import {
  fadeInAnimation,
  fadeInOnEnterAnimation,
  fadeOutAnimation,
  fadeOutOnLeaveAnimation,
  rotateAnimation,
  rotateInAnimation,
  rotateOutAnimation,
} from 'angular-animations';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MenuComponent } from './menu/menu.component';

const breakpoints = [Breakpoints.XSmall, Breakpoints.Small];

interface NavigationData {
  text: string;
  url?: any[];
  children?: NavigationData[];
  icon?: string;
}

const TREE_DATA: NavigationData[] = [
  {
    text: 'Test',
    icon: 'payment',
    url: ['absas']
  },
  {
    text: 'Build',
    icon: 'build',
    url: ['absas']
  },
  {
    text: 'Gif',
    icon: 'gif',
    url: ['absas']
  },
  {
    text: 'Today',
    icon: 'today',
    url: ['absas']
  },
  {
    text: 'Children',
    icon: 'home',
    children: [
      {
        text: 'tt',
        // icon: 'home',
        url: ['absas']
      },
      {
        text: 'tada',
        children: [
          {
            text: 'tt',
            url: ['absas']
          },
          {
            text: 'tada',
            url: ['absas']
          },
        ],
      },
    ],
  },
  {
    text: 'Upgrade',
    icon: 'upgrade',
    url: ['absas']
  }
];

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    rotateOutAnimation({ anchor: 'rotateOut', duration: 200 }),
    rotateInAnimation({ anchor: 'rotateIn', duration: 200 }),
    rotateOutAnimation({
      anchor: 'rotateOutExpanded',
      duration: 200,
      degrees: -200,
    }),
    rotateInAnimation({
      anchor: 'rotateInExpanded',
      duration: 200,
      degrees: 200,
    })
  ],
})
export class LayoutComponent {
  @ViewChild(MenuComponent) menu: MenuComponent;

  icons = {
    menu: faBars,
    envelope: faEnvelope,
    times: faTimes,

    home: faHome,
    profile: faUser,

    caretRight: faCaretRight
  };

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(breakpoints)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  detached = false;
  extended = true;

  sidenavButtonIcon: IconDefinition = this.icons.menu;
  rotateOutAnimation = false;
  rotateInAnimation = false;
  rotateOutExpandedAnimation = false;
  rotateInExpandedAnimation = false;

  user = this.authService.user$;

  treeData = TREE_DATA;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService
  ) {
  }

  toggleMenu() {
    this.detached = !this.detached;
  }

  logout() {
    this.authService.logout({ returnTo: window.location.origin });
  }

  onSidenavAnimationChange(isStart: boolean) {
    if (!isStart) {
      this.rotateOutExpandedAnimation = true;
    } else {
      this.rotateOutAnimation = true;
    }
  }

  rotateOutSidenavButtonDone(event: AnimationEvent, isExpanded: boolean) {
    if (((event.toState as unknown) as boolean) === true) {
      if (isExpanded) {
        this.sidenavButtonIcon = this.icons.menu;
        this.rotateOutExpandedAnimation = false;
        this.rotateInExpandedAnimation = true;
      } else {
        this.sidenavButtonIcon = this.icons.times;
        this.rotateOutAnimation = false;
        this.rotateInAnimation = true;
      }
    }
  }

  rotateInSidenavButtonDone(event: AnimationEvent, isExpanded: boolean) {
    if (isExpanded) {
      this.rotateInExpandedAnimation = false;
    } else {
      this.rotateInAnimation = false;
    }
  }

  extendToggle() {
    this.menu.collapseAll();
    this.extended = !this.extended;
  }
}
